import { get, pick } from 'lodash/fp';

import constants from '../../ahm-constants';

const {
  URLS: {
    SALES: { EXPLAINED },
  },
} = constants;

// @ts-ignore - Automatic, Please fix when editing this file
const getLinkForExplainedTerm = (explainedTerm) => {
  const { detailsTeaser, standAloneInformation, urlObject } = pick(
    ['detailsTeaser', 'standAloneInformation', 'urlObject'],
    explainedTerm,
  );

  const slug = get('identifier.current', explainedTerm);
  const standAlonePath = slug ? `${EXPLAINED}/${slug}` : null;

  const urlName = get('name', urlObject);
  const url = get('url', urlObject);

  const ctaObject = standAloneInformation
    ? standAlonePath && detailsTeaser && { link: standAlonePath, linkText: detailsTeaser }
    : urlName && url && { link: url, linkText: urlName };
  return ctaObject;
};

export default getLinkForExplainedTerm;
