import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { get, isEmpty, isNil } from 'lodash/fp';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { Wrapper } from '@ahmdigital/ui';
import React, { useEffect } from 'react';

import { getCookieForMembers } from '../../utils/cookie-for-members';
import { GROWTHBOOK_HOMEPAGE_OFFER_BANNER } from '../../ahm-constants/ui';
import { useCookieContext } from '../../contexts/cookie';
import Awards from '../../components/awards';
import ContentTiles from './components/content-tiles';
import getBannerProps from './utils/get-hero-section-content';
import getHomeHero from './utils/get-home-hero';
import getOfferOrPerkTile from './utils/get-offer-or-perk-tile';
import PriceActions from '../../actions/price';
import PriceService from '../../models/price';
import ProductActions from '../../actions/product';
import ProductPropType from '../../types/product';
import ProductService from '../../models/product';
import ReferrerActions from '../../actions/referrer';
import selectCustomer from '../../selectors/select-customer';
import selectHasPartnerReferrer from '../../selectors/select-has-partner-referrer';
import selectLastViewedProduct from '../../selectors/select-last-viewed-product';
import type { CmsBanner } from './types';
import type { ContentTilesProps } from './components/content-tiles';

const { getPrice } = PriceService;
const { getProduct } = ProductService;

type HomeProps = {
  actions: {
    referrer: {
      setHasPartnerReferrer: (hasPartnerReferrer: boolean) => void;
    };
    price: {
      load: (id: string, data: {}, options?: {}) => void;
    };
    product: {
      load: (id: string) => void;
    };
  };
  content: {
    banners: CmsBanner[];
    announcements: ContentTilesProps['items'];
  };
  customer: any;
  isPartnerReferral: boolean;
  lastViewedProductId: string;
  prices: any;
  products: {
    [key: string]: ProductPropType;
  };
};

const HomePage = ({
  actions,
  content,
  customer,
  isPartnerReferral,
  lastViewedProductId,
  prices,
  products,
}: HomeProps) => {
  const router = useRouter();
  const params = router.query;
  const serverCookieValue = useCookieContext() as string;
  const memberNumber = get('id', getCookieForMembers('health', { serverCookieValue }));
  const hasLastViewedProduct = !isNil(lastViewedProductId);
  const isHomepageOfferBannerEnabled = useFeatureIsOn(GROWTHBOOK_HOMEPAGE_OFFER_BANNER);

  const lastViewedProduct = hasLastViewedProduct ? getProduct(products, lastViewedProductId) : null;

  const { load: loadProduct } = actions.product;
  const { load: loadPrice } = actions.price;

  useEffect(() => {
    if (hasLastViewedProduct && isEmpty(lastViewedProduct)) {
      loadProduct(lastViewedProductId);
    }
  }, [lastViewedProduct, lastViewedProductId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasLastViewedProduct && !getPrice(prices, lastViewedProductId, customer)) {
      loadPrice(lastViewedProductId, customer);
    }
  }, [lastViewedProductId, prices]); // eslint-disable-line react-hooks/exhaustive-deps

  const { source } = params;

  useEffect(() => {
    if (source === 'raiz') {
      actions.referrer.setHasPartnerReferrer(true);
    }
  }, [actions.referrer, source]);

  const { heroSectionProps, diversifiedHeroProps } = getBannerProps(content);
  const offerProps = {
    activeOffers: get('activeOffers', content),
    placeholders: get('placeholders', content),
  };

  return (
    <Wrapper size="extraExtraLarge">
      {getHomeHero({
        diversifiedHeroProps,
        heroSectionProps,
        isPartnerReferral,
        lastViewedProduct,
        lastViewedProductId,
        memberNumber,
        offerProps,
      })}
      {getOfferOrPerkTile({
        isHomepageOfferBannerEnabled,
        isPartnerReferral,
        lastViewedProduct,
        offerProps,
        serverCookieValue,
      })}
      <Awards />
      <ContentTiles items={get('announcements', content)} />
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: {
    price: bindActionCreators(PriceActions, dispatch),
    product: bindActionCreators(ProductActions, dispatch),
    referrer: bindActionCreators(ReferrerActions, dispatch),
  },
});

const mapStateToProps = (state: any) => ({
  customer: selectCustomer(state),
  isPartnerReferral: selectHasPartnerReferrer(state),
  lastViewedProductId: selectLastViewedProduct(state),
  prices: state.price.data,
  products: state.product.data,
});

// @ts-ignore - Automatic, Please fix when editing this file
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
export { HomePage as HomePageForTest, mapStateToProps };
