import useTableOfContents from '../../../src/client/cms/utils/use-table-of-contents';

// @ts-ignore - Automatic, Please fix when editing this file
const useGetTableOfContentsLists = ({ fragment, shouldSkip, slug }) => {
  const { items, loading } = useTableOfContents({
    fragment,
    skip: shouldSkip,
    slug,
  });
  return { data: items, loading };
};

export default useGetTableOfContentsLists;
