import { get, set } from 'lodash/fp';
import { getGrowthBookSSRData, GrowthBookSSRData, useGrowthBookSSR } from '@growthbook/growthbook-react';
import React from 'react';

import config from '../../src/client/config';

// @ts-ignore - Automatic, Please fix when editing this file
export const generateGrowthBookSSRProps = async ({ req, resolvedUrl }) => {
  const gbClientId = get('cookies.gbClientId')(req);
  const host = get('headers.host')(req);

  return getGrowthBookSSRData({
    apiHost: config.get('growthbook:apiHost'),
    attributes: {
      id: gbClientId || '',
      url: `${host}${resolvedUrl}`,
    },
    clientKey: config.get('growthbook:clientKey'),
  });
};

// @ts-ignore - Automatic, Please fix when editing this file
export const injectGrowthBookProps = async ({ context, props }) => {
  const growthBookData = await generateGrowthBookSSRProps(context);
  return {
    props: set('growthBookData', growthBookData, props),
  };
};

type GrowthBookPageWrapperProps = {
  growthBookData: GrowthBookSSRData;
};

export const GrowthBookPageWrapper = (
  props: GrowthBookPageWrapperProps,
  Component: React.ComponentType,
): JSX.Element => {
  const { growthBookData, ...rest } = props;
  // This is required once at the top of the SSR page
  useGrowthBookSSR(growthBookData);

  return <Component {...rest} />;
};
