import { get, head } from 'lodash/fp';
import { gql, useQuery } from '@apollo/client';

import { GRAPHQL } from '../../../../../cms/constants';
import uniqueContentWithDraftPrecedence from '../../../../../cms/utils/unique-content-with-draft-precedence';

const GET_PERK = gql`
  query getMemberPerk($id: String) {
    allMemberPerk(where: { identifier: { current: { eq: $id } } }) {
        descriptionTeaser
        _id
        id: _id
        ${GRAPHQL.IMAGE_OBJECT}
        name
        ${GRAPHQL.RELEASE}
        slogan
        ${GRAPHQL.SLUG}
        ${GRAPHQL.URL_OBJECT}
    }
  }
`;

// @ts-ignore - Automatic, Please fix when editing this file
const useGetPerk = (perkId) => {
  const response = useQuery(GET_PERK, {
    variables: { id: perkId },
  });
  const { data } = response;
  return head(uniqueContentWithDraftPrecedence(get('allMemberPerk', data)));
};

export default useGetPerk;
