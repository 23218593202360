import constants from '../../../ahm-constants';
import hero5PeopleDesktopImage from './images/hero-5-people-desktop.png';
import hero5PeopleMobileImage from './images/hero-5-people-mobile.png';
import hero5PeopleTabletImage from './images/hero-5-people-tablet.png';
import type { DiversifiedHeroProps, HeroSectionProps } from '../types';

export const diversifiedHeroData: DiversifiedHeroProps = {
  urlObject: {
    name: 'Manage your health cover',
    url: constants.URLS.MEMBERS.HOME,
  },
};

export const heroSectionData: HeroSectionProps = {
  backgroundImage: {
    desktop: {
      image: hero5PeopleDesktopImage.src,
      position: 'center',
    },
    mobile: {
      image: hero5PeopleMobileImage.src,
    },
    tablet: {
      image: hero5PeopleTabletImage.src,
      position: 'center',
    },
  },
  urlObject: {
    name: 'Find cover',
    url: constants.URLS.SALES.REFINE_PRICE,
  },
};
