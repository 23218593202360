import { Button, Col, Image, Row, SpacerWrapper } from '@ahmdigital/ui';
import { get, isNil, mapValues } from 'lodash/fp';
import React from 'react';

import buildCtaProps from '../../../../cms/utils/perks/build-cta-props';
import getImageAttributes from '../../../../cms/utils/get-image-attributes';
import HeaderWithSubtext from '../../../../components/offers/tile/components/header-with-subtext';
import styles from './styles.module.sass';
import useGetPerk from './use-get-perk';

type PerkTileProps = {
  perkId: string;
};

const PerkTile = ({ perkId }: PerkTileProps) => {
  const perk = useGetPerk(perkId);
  if (isNil(perk)) {
    return null;
  }

  const image = getImageAttributes({ imageObject: get('imageObject', perk), width: 400 });
  const header = { type: 'portableText', value: get('name', perk) };
  const subtext = { type: 'portableText', value: get('slogan', perk) };
  const ctaProps = buildCtaProps(perk);
  const imageColProps = {
    df: 6,
    sm: 12,
  };
  const copyColProps = mapValues((width) => (width !== 12 ? 12 - width : 12), imageColProps);

  return (
    <Row isBoxed>
      <Col df={12}>
        <SpacerWrapper variant={['paddingTopSpacer2', 'paddingBottomSpacer2', styles.section]}>
          <Row>
            {image ? (
              <Col variant={styles.imageWrapper} key="image" {...imageColProps}>
                <Image className={styles.image} src={image.src} alt={image.alt} />
              </Col>
            ) : null}
            <Col {...copyColProps}>
              {/* @ts-ignore - Automatic, Please fix when editing this file */}
              <HeaderWithSubtext {...{ header, subtext }} />
              {get('ctaUrl', ctaProps) && (
                <Row>
                  <Col sm={12}>
                    <Button to={ctaProps.ctaUrl} variant={['border', 'transparentBlack']}>
                      {ctaProps.ctaText}
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </SpacerWrapper>
      </Col>
    </Row>
  );
};

export default PerkTile;
