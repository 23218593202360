import React from 'react';
import styled from 'styled-components';

type ImageType = {
  image: string;
  position?: string;
};

type BackgroundImageType = {
  className?: string;
  children: React.ReactNode;
  desktop: ImageType;
  tablet: ImageType;
  mobile?: ImageType;
};

const BackgroundImage = ({ className, children }: BackgroundImageType) => <div className={className}>{children}</div>;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: contain;

  ${({ desktop, tablet, theme }) => `
    @media (min-width: ${theme.media.sm}) {
      background-image: url('${tablet.image}');
      background-position: ${tablet.position};
    }

    @media (min-width: ${theme.media.md}) {
      background-image: url('${desktop.image}');
      background-position: ${desktop.position};
    }
  `}
`;

export default StyledBackgroundImage;
