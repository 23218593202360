import { useQuery } from '@apollo/client';

import { GET_ALL_BANNER_QUERY } from '../../../src/client/cms/queries';
import filterHomePageBanners from '../filter-home-page-banners';

// @ts-ignore - Automatic, Please fix when editing this file
const useGetHomeBannersQuery = ({ shouldSkip }) => {
  const { data: cmsBannerData, loading } = useQuery(GET_ALL_BANNER_QUERY, { skip: shouldSkip });
  const banners = filterHomePageBanners(cmsBannerData);

  return { banners, loading };
};

export default useGetHomeBannersQuery;
