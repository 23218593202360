import { isString } from 'lodash/fp';

import { PRODUCT_LOAD, PRODUCT_LOAD_FAIL, PRODUCT_LOAD_SUCCESS } from '../action-types';
import ProductService from '../../models/product';

/**
 * Load products into state.
 *
 * @param {string|string[]} productIds - One or a list of product ids.
 * @returns {object} Action.
 */
// @ts-ignore - Automatic, Please fix when editing this file
function load(productIds) {
  const array = isString(productIds) ? [productIds] : productIds;

  return {
    productIds: array,
    // @ts-ignore - Automatic, Please fix when editing this file
    promise: (api) => ProductService.loadProducts(api, array),
    types: [PRODUCT_LOAD, PRODUCT_LOAD_SUCCESS, PRODUCT_LOAD_FAIL],
  };
}

export default {
  load,
};
